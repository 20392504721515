import axios from "axios";

const instance = axios.create({
  baseURL:
    // 'https://portal.labayh.net/'
    // "https://8b59-2-88-149-233.ngrok-free.app",
    process.env.REACT_APP_COMPANY_API,
});
instance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("ctoken");
    const lang = await localStorage.getItem("lang");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;

      config.headers.lang = `${lang ? lang : "ar"}`;
    }
    // return {
    //   ...config,
    //   headers: { ...config.headers, "ngrok-skip-browser-warning": "testing" },
    // };

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const oldRefreshToken = await localStorage.getItem("crefreshToken");

        const response = await axios.post(
          `${process.env.REACT_APP_COMPANY_API}/api/company-admin/refresh-token`,
          { refreshToken: oldRefreshToken }
        );
        const { accessToken, refreshToken } = response.data;

        if (accessToken && refreshToken) {
          localStorage.setItem("ctoken", accessToken);
          localStorage.setItem("crefreshToken", refreshToken);
        }

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;

        return axios(originalRequest);
      } catch (error) {
        // Handle refresh token error or redirect to login
      }
    }

    return Promise.reject(error);
  }
);
export const StagingPortal = instance;
export default instance;
