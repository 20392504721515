import CreateContext from "./createContext";
import LabayhAdmin from "../api/labayhAPI";
import LabayhMainAPI from "../api/labahMainAPI";
import MobileApi from "../api/mobileAPI";
import { FormattedMessage } from "react-intl";
import validator from "validator";
import { useContext } from "react";

const socketReducer = (state, action) => {
  switch (action.type) {
    case "sign_in":
      return { ...state, token: action.payload };
    case "sign_out":
      return { ...state, token: "" };
    case "add_companie":
      return { ...state };
    case "error_message":
      return { ...state, erroeMessage: action.payload };
    case "clear_error_message":
      return { ...state, erroeMessage: "" };
    case "specific_company":
      return { ...state, SpecificCompaniesData: action.payload };
    case "specific_company_logo":
      return { ...state, SpecificCompanyLogo: action.payload };
    case "specific_company_name":
      return { ...state, SpecificCompanyName: action.payload };
    case "specific_company_admins":
      return { ...state, SpecificCompanyAdmins: action.payload };
    case "specific_company_logs":
      return { ...state, CompanyLogsData: action.payload };
    case "specific_Admin_User_logs":
      return { ...state, CompanyLogsAdminUser: action.payload };
    case "company_rname":
      return { ...state, rname: action.payload };
    case "change_lang":
      return { ...state, lang: action.payload };
    case "specific_company_analytics":
      return { ...state, analyticsData: action.payload };
    case "users_with_zero_balance":
      return { ...state, usersZeroBalnce: action.payload };
    case "specific_company_employees":
      return { ...state, SpecificCompaniesEmployees: action.payload };
    case "specific_company_employees_phones":
      return { ...state, SpecificCompaniesEmployeesPhones: action.payload };
    default:
      return state;
  }
};
const signin =
  (dispatch) => async (email, password, company, cbToNavigateToMainFlow) => {
    let lang = await localStorage.getItem("lang");
    if (!validator.isEmail(email)) {
      dispatch({
        type: "error_message",
        payload: <FormattedMessage id="ValidEmail" />,
      });
    } else {
      email = email.trim().toLowerCase();
      password = password.trim();
      try {
        const response = await LabayhAdmin.post(
          `/api/company-admin/login/${company}`,
          { email, password },
          {
            headers: {
              lang,
            },
          }
        );

        await localStorage.setItem("ctoken", response.data.token);
        await localStorage.setItem("cId", response.data.admin.company);
        await localStorage.setItem("crefreshToken", response.data.refreshToken);
        await localStorage.setItem("cUser", JSON.stringify(response.data.admin));
        dispatch({ type: "sign_in", payload: response.data.token });
        cbToNavigateToMainFlow(response.data.admin);
      } catch (error) {
        dispatch({
          type: "error_message",
          payload: error.response ? (
            error.response.data.error
          ) : (
            <FormattedMessage id="error501" />
          ),
        });
      }
    }
  };
const tryLocalSignin =
  (dispatch) =>
  async (cbToNavigateToMainFlow, cbToNavigateToLogin, cbToNavigateToLabayh) => {
    const token = await localStorage.getItem("ctoken");
    const name = await localStorage.getItem("routeName");
    const lang = await localStorage.getItem("lang");
    if (lang) {
      dispatch({ type: "change_lang", payload: lang });
    }
    try {
      if (token) {
        const response = await LabayhAdmin.get(
          "/api/company-admin/refresh-token"
        );
        if (response.data._id) {
          const user = response.data;

          if (name) {
            dispatch({ type: "company_rname", payload: name });
            dispatch({ type: "sign_in", payload: token });
            cbToNavigateToMainFlow(name, user);
          }
        } else {
          await localStorage.removeItem("ctoken");
          if (name) {
            dispatch({ type: "company_rname", payload: name });
            cbToNavigateToLogin(name);
          } else {
            cbToNavigateToLabayh();
          }
        }
      } else {
        cbToNavigateToLabayh();
      }
    } catch (e) {
      cbToNavigateToLabayh();
    }
  };

export const signout = (dispatch) => async () => {
  try {
    await LabayhAdmin.post("/api/company-admin/logout", {
      refreshToken: await localStorage.getItem("crefreshToken"),
    });
  } catch (error) {
    throw error;
  }
  await localStorage.removeItem("ctoken");
  await localStorage.removeItem("crefreshToken");
  await localStorage.removeItem("routeName");
  await localStorage.removeItem("lang");
  await localStorage.removeItem("cUser");
  dispatch({ type: "sign_out" });
  

 
};
const setName = (dispatch) => async (name) => {
  await localStorage.setItem("routeName", name);
  dispatch({ type: "company_rname", payload: name });
};

const addUsers = (dispatch) => async (userIds, cbToNavigateToDispalyAll) => {
  try {
    await LabayhAdmin.post("/api/company-admin/add-users", { userIds });
    cbToNavigateToDispalyAll();
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.error
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const addUserWithNumber =
  (dispatch) =>
  async (mobile, countryCode, companyId, cbToNavigateToDispalyAll) => {
    try {
      if (!validator.isMobilePhone(`+${countryCode}${mobile}`)) {
        dispatch({
          type: "error_message",
          payload: <FormattedMessage id="ValidPhone" />,
        });
        return;
      }
      await LabayhMainAPI.post(`/companies/${companyId}/users`, {
        mobile,
        countryCode,
      });
      cbToNavigateToDispalyAll();
    } catch (error) {
      dispatch({
        type: "error_message",
        payload: error.response ? (
          error.response.data.message
        ) : (
          <FormattedMessage id="error501" />
        ),
      });
    }
  };

const deleteUsers = (dispatch) => async (userIds, cbToNavigateToDispalyAll) => {
  try {
    await LabayhAdmin.post("/api/company-admin/delete-users", { userIds });

    cbToNavigateToDispalyAll();
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.error
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const deleteUserWithNumber =
  (dispatch) =>
  async (mobile, countryCode, companyId, cbToNavigateToDispalyAll) => {
    try {
      if (!validator.isMobilePhone(`+${countryCode}${mobile}`)) {
        dispatch({
          type: "error_message",
          payload: <FormattedMessage id="ValidPhone" />,
        });
        return;
      }
      await LabayhMainAPI.delete(`/companies/${companyId}/users`, {
        data: { mobile, countryCode },
      });
      cbToNavigateToDispalyAll();
    } catch (error) {
      dispatch({
        type: "error_message",
        payload: error.response ? (
          error.response.data.message
        ) : (
          <FormattedMessage id="error501" />
        ),
      });
    }
  };

const fetchSpecificCompany = (dispatch) => async () => {
  try {
    const response = await LabayhAdmin.get("/api/company-admin/get-data");
    dispatch({ type: "specific_company", payload: response.data.foundCopmany });
    dispatch({
      type: "specific_company_admins",
      payload: response.data.admins,
    });
    dispatch({
      type: "specific_company_logo",
      payload: `${process.env.REACT_APP_ASSETS}/${response.data.foundCopmany.logo}`,
    });
    const response2 = await LabayhAdmin.get("/api/company-admin/get-logs");
    dispatch({ type: "specific_company_logs", payload: response2.data.wallet });

    const response3 = await LabayhAdmin.get(
      "/api/company-admin/admin-users-logs"
    );
    dispatch({ type: "specific_Admin_User_logs", payload: response3.data });
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.error
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const updateCompanyInfo =
  (dispatch) =>
  async (
    logo,
    email,
    name,
    businessType,
    allowUsers,
    contactAgentEmail,
    contactAgentPhone,
    cbToEnableBackEditButton
  ) => {
    let sendReq = true;

    if (!validator.isEmail(email)) {
      dispatch({
        type: "error_message",
        payload: <FormattedMessage id="ValidEmail" />,
      });
      sendReq = false;
    }
    if (!validator.isEmail(email)) {
      dispatch({
        type: "error_message",
        payload: <FormattedMessage id="ValidEmailAgent" />,
      });
      sendReq = false;
    }

    if (!Number(contactAgentPhone)) {
      dispatch({
        type: "error_message",
        payload: <FormattedMessage id="contactPhoneAgent" />,
      });
      sendReq = false;
    }

    if (sendReq) {
      try {
        let logoUrl = logo;
        if (logo.name) {
          const formData = new FormData();
          formData.append("file", logo);
          try {
            const image = await MobileApi.post("/api/images/upload", formData);

            if (image.data.error) {
              dispatch({
                type: "error_message",
                payload: <FormattedMessage id="imageUploadError" />,
              });
            }
            logoUrl = image.data.image;
          } catch (error) {
            dispatch({
              type: "error_message",
              payload: <FormattedMessage id="imageUploadError" />,
            });
          }
        }

        await LabayhAdmin.post("/api/company-admin/edit-company-data", {
          name,
          email,
          businessType,
          allowUsers,
          contactAgentEmail,
          contactAgentPhone,
          logo: logoUrl,
        });
        // await LabayhAdmin.post('/api/company-admin/edit-all-users-status', { status: allowUsers })
        cbToEnableBackEditButton();

        fetchSpecificCompany();
      } catch (error) {
        dispatch({
          type: "error_message",
          payload: error.response ? (
            error.response.data.error
          ) : (
            <FormattedMessage id="error501" />
          ),
        });
      }
    }
  };

const adminChangeStatus = (dispatch) => async (status, cbToNavigateBack) => {
  try {
    await LabayhAdmin.post("/api/company-admin/edit-all-users-status", {
      status,
    });
    cbToNavigateBack();
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.error
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const adminChangeUserStatus =
  (dispatch) => async (status, employeeId, cbToNavigateBack) => {
    try {
      await LabayhAdmin.post("/api/company-admin/edit-one-users-status", {
        status,
        employeeId,
      });

      cbToNavigateBack();
    } catch (error) {
      dispatch({
        type: "error_message",
        payload: error.response ? (
          error.response.data.error
        ) : (
          <FormattedMessage id="error501" />
        ),
      });
    }
  };
const adminChangeUserBalance =
  (dispatch) => async (amount, employeeId, cbToNavigateBack) => {
    try {
      await LabayhAdmin.post("/api/company-admin/edit-one-users-balance", {
        amount,
        employeeId,
      });
      cbToNavigateBack();
    } catch (error) {
      dispatch({
        type: "error_message",
        payload: error.response ? (
          error.response.data.error
        ) : (
          <FormattedMessage id="error501" />
        ),
      });
    }
  };
const anotherCompLogo = (dispatch) => async (slug) => {
  try {
    const response = await LabayhAdmin.get(
      `/api/company-admin/get-logo/${slug}`
    );
    dispatch({ type: "specific_company_name", payload: response.data.name });

    dispatch({
      type: "specific_company_logo",
      payload: `${process.env.REACT_APP_ASSETS}/${response.data.logo}`,
    });
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.error
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const clearErrorMessage = (dispatch) => () => {
  dispatch({ type: "clear_error_message" });
};

const changeLnag = (dispatch) => async (lang) => {
  dispatch({ type: "change_lang", payload: lang });
  await localStorage.setItem("lang", lang);
};

const analytics = (dispatch) => async () => {
  // api/analytics/get
  try {
    const response = await LabayhAdmin.get("/api/analytics/get");
    dispatch({ type: "specific_company_analytics", payload: response.data });
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.error
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const getUsersWithZeroBalance = (dispatch) => async () => {
  // /api/company-admin/employee-zero-balance
  let lang = await localStorage.getItem("lang");
  try {
    const response = await LabayhAdmin.get(
      "/api/company-admin/employee-zero-balance/",
      {},
      {
        headers: {
          lang,
        },
      }
    );
    dispatch({
      type: "users_with_zero_balance",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.error
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const getPaginatedEmployeeEmails = (dispatch) => async (companyID, page) => {
  try {
    const response = await LabayhMainAPI.get(
      `/companies/${companyID}/emails?page=${page}&limit=10`
    );
    dispatch({ type: "specific_company_employees", payload: response.data });
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.message
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const getPaginatedEmployeeNumbers = (dispatch) => async (companyID, page) => {
  try {
    const response = await LabayhMainAPI.get(
      `/companies/${companyID}/users?page=${page}&limit=10`
    );
    dispatch({
      type: "specific_company_employees_phones",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "error_message",
      payload: error.response ? (
        error.response.data.message
      ) : (
        <FormattedMessage id="error501" />
      ),
    });
  }
};

const clearEmplyeeNumbers = (dispatch) => () => {
  dispatch({ type: "specific_company_employees", payload: [] });
};

const addEmployeesToCompany =
  (dispatch) =>
  async (
    { employees = [], totalUsersAppointmentsCount },
    companyId,
    isEmails = false,
    cbToNavigateToDispalyAll
  ) => {
    if (!employees.length) {
      cbToNavigateToDispalyAll();
      dispatch({
        type: "error_message",
        payload: "you must provide correct phone numbers",
      });
    }

    try {
      if (!isEmails) {
        await LabayhMainAPI.post(`/companies/${companyId}/users`, {
          employeeNumbers: employees,
        });
      } else if (isEmails) {
        await LabayhMainAPI.post(`/company-user-emails`, {
          company: companyId,
          emailsWithMembers: employees,
          totalUsersAppointmentsCount,
        });
      }

      cbToNavigateToDispalyAll();
    } catch (error) {
      dispatch({
        type: "error_message",
        payload: error.response ? (
          error.response.data.error
        ) : (
          <FormattedMessage id="error501" />
        ),
      });
      cbToNavigateToDispalyAll();
    }
  };

const deleteEmployeeByEmail =
  (dispatch) =>
  async ({ emailId, company }, cbToNavigateToDispalyAll) => {
    try {
      await LabayhAdmin.patch(`/api/employee/delete-employees`, {
        emails: [emailId],
        company,
      });
      cbToNavigateToDispalyAll();
    } catch (error) {
      dispatch({
        type: "error_message",
        payload: error.response ? (
          error.response.data.message
        ) : (
          <FormattedMessage id="error501" />
        ),
      });
    }
  };

const deleteEmployeeByPhone =
  (dispatch) => async (companyId, userPhoneId, cbToNavigateToDispalyAll) => {
    try {
      await LabayhMainAPI.delete(
        `/companies/${companyId}/users/${userPhoneId}/remove-phone`
      );
      cbToNavigateToDispalyAll();
    } catch (error) {
      dispatch({
        type: "error_message",
        payload: error.response ? (
          error.response.data.message
        ) : (
          <FormattedMessage id="error501" />
        ),
      });
    }
  };

export const { Context, Provider } = CreateContext(
  {
    token: "",
    CompanyLogsData: [],
    erroeMessage: "",
    rname: "",
    SpecificCompaniesData: undefined,
    SpecificCompanyAdmins: [],
    analyticsData: [],
    usersZeroBalnce: [],
    SpecificCompanyLogo: "",
    CompanyLogsAdminUser: [],
    lang: "ar",
    SpecificCompanyName: "",
    SpecificCompaniesEmployees: [],
    SpecificCompaniesEmployeesPhones: [],
  },
  {
    signin,
    tryLocalSignin,
    signout,
    anotherCompLogo,
    addUsers,
    updateCompanyInfo,
    deleteUsers,
    setName,
    changeLnag,
    adminChangeUserStatus,
    adminChangeStatus,
    adminChangeUserBalance,
    fetchSpecificCompany,
    clearErrorMessage,
    analytics,
    getUsersWithZeroBalance,
    addUserWithNumber,
    deleteUserWithNumber,
    getPaginatedEmployeeNumbers,
    clearEmplyeeNumbers,
    addEmployeesToCompany,
    deleteEmployeeByEmail,
    getPaginatedEmployeeEmails,
    deleteEmployeeByPhone,
  },
  socketReducer
);

export const useLabayhContext = () => useContext(Context);
