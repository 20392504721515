import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Context } from "./context/LabayhAdmin";
import ProtectedRoute from "./Protected.route";
import { IntlProvider } from "react-intl";
import TagManager from "react-gtm-module";

import Arabic from "./translations/ar.json";
import English from "./translations/en.json";

import "./App.css";
import "./scss/style.scss";
import "./output.css";
import "react-datepicker/dist/react-datepicker.css";
import "./scss/datepicker.css";

import SurveyNavigation from "./nav/SurveyNavigation";

import BuilderSurvey from "./components/pages/BuilderSurvey";

import { UserProvider } from "./context/userCompany";
import { LanguageProvider } from "./hooks/useLang";
import { ThemeProvider } from "./context/useTheme";
import { useCompanyInfo } from "./hooks/useCompanyInfo";
import { CustomerThemeProvider } from "./context/useCustomTheme";

// import SEO from './seo/SEO.jsx'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgetPassword = React.lazy(() =>
  import("./views/pages/forget-password/ForgetPassword")
);
const SetPassword = React.lazy(() => import("./views/mainFlow/SetPassword"));
const LandingPage = React.lazy(() => import("./views/pages/landing/landing"));
const Page404 = React.lazy(() => import("./components/pages/Survey404"));
// NOTE: THIS IS OLD FORM 👇
// const BasicForms = React.lazy(() => import("./views/mainFlow/forms"));
const TypeForms = React.lazy(() => import("./views/mainFlow/formTypeForm"));

const GTM_ID = "GTM-PN6MKMFR"; // Replace with your GTM ID

const App = () => {
  const [user, setUser] = useState({
    email: "",
    name: "",
    type: "",
    _id: "",
    company: "",
  });
  const {
    state: { lang },
  } = useContext(Context);

  const companyName = window.location.pathname;
  let name = companyName.split("/")[1];
  let nowRouter = companyName.split("/")[2];

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  useEffect(() => {
    if (nowRouter) {
      TagManager.dataLayer({
        event: `view-${nowRouter}-page`,
      });
    }
  }, [nowRouter]);

  return (
    <UserProvider.Provider value={{ user, setUser }}>
      <LanguageProvider>
        <CustomerThemeProvider>
          <ThemeProvider>
            <IntlProvider
              locale={lang}
              messages={lang === "ar" ? Arabic : English}
            >
              {lang === "ar"
                ? document
                    .getElementsByTagName("html")[0]
                    .setAttribute("dir", "rtl")
                : document
                    .getElementsByTagName("html")[0]
                    .setAttribute("dir", "ltr")}
              {/* font-family: */}

              <React.Suspense fallback={loading}>
                <Switch>
                  {process.env.NODE_ENV === "development" && (
                    <Route
                      exact
                      path={`/builder`}
                      name={"builder-form"}
                      render={(props) => <BuilderSurvey {...props} />}
                    >
                      <BuilderSurvey />
                    </Route>
                  )}

                  <Route
                    exact
                    path={`/404`}
                    render={(props) => <Page404 {...props} />}
                  />
                  <Route
                    exact
                    path={`/${name}`}
                    render={(props) => (
                      <LandingPage nowRouter={nowRouter} {...props} />
                    )}
                  />

                  <Route
                    exact
                    path={`/${name}/login`}
                    name="Login Page"
                    render={(props) => <Login {...props} />}
                  />
                  <Route
                    exact
                    path={`/${name}/forget-password`}
                    name="Forget Page"
                    render={(props) => <ForgetPassword {...props} />}
                  />

                  <Route
                    exact
                    path={`/${name}/callback`}
                    name="Set Password"
                    render={(props) => <SetPassword {...props} />}
                  />

                  <Route
                    exact
                    path={`/${name}/survey2`}
                    name="Form Page2"
                    render={(props) => <TypeForms {...props} />}
                  />
                  {/* <Route path={"/survey/"}> */}
                  {/* </Route> */}
                  <Route path={"/survey"}>
                    <SurveyNavigation />
                  </Route>
                  <ProtectedRoute
                    path={`/${name}`}
                    name="Home"
                    component={TheLayout}
                  />
                  {/* <Route path={"*"}>
            <NotFoundPage />
          </Route> */}
                </Switch>
              </React.Suspense>
            </IntlProvider>
          </ThemeProvider>
        </CustomerThemeProvider>
      </LanguageProvider>
    </UserProvider.Provider>
  );
};

const AppMain = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};
export default AppMain;
